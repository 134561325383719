import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddClassModal = ({ show, handleClose, saveClass, deleteClass, initialData }) => {
  const [className, setClassName] = useState('');
  const [description, setDescription] = useState('');
  const [cadence, setCadence] = useState('');
  const [cost, setCost] = useState('');
  const [displayCost, setDisplayCost] = useState(''); // holds the formatted display value


//INITIALIZE DATA
  useEffect(() => {
    if (show) {
      if (initialData) {
        setClassName(initialData.class_name || '');
        setDescription(initialData.description || '');
        setCadence(initialData.cadence || '');
        setCost(initialData.cost || '');
        setDisplayCost(formatCurrency(initialData.cost));
      } else {
        setClassName('');
        setDescription('');
        setCadence('');
        setCost('');
        setDisplayCost('');
      }
    }
  }, [show, initialData]);


  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleCostChange = (e) => {
    const value = e.target.value;
    if (value === '' || value === '$') {
      setCost(0);
      setDisplayCost('');
      return;
    }
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    if (!isNaN(numericValue)) {
      setCost(numericValue);
      setDisplayCost(formatCurrency(numericValue));
    } else {
      setDisplayCost(value);
    }
  };

//CREATE OR UPDATE CLASS
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...(initialData && { id: initialData.id }), 
      class_name: className,
      description: description,
      cadence: cadence,
      cost: cost
    };
    console.log('Submitting class data:', dataToSubmit);

    saveClass(dataToSubmit);
    handleClose();
  };

  // DELETE MEMNBER
  const handleDelete = () => {
    if (initialData.id) {
        deleteClass(initialData.id);
        handleClose();
    }
  };


  return (
    <Modal show={show} onHide={handleClose} className="fade" dialogClassName="modal-dialog">
      <Modal.Header closeButton>
        <Modal.Title>{initialData ? 'Editar servicio' : 'Agregar servicio'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalbody'>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre del servicio</Form.Label>
            <Form.Control
              type="text"
              value={className}
              onChange={e => setClassName(e.target.value)}
              required
            />
          </Form.Group>


          <Form.Group>
            <Form.Label>Costo</Form.Label>
            <Form.Control
              type="text" // use type text to allow formatting
              value={displayCost}
              onChange={handleCostChange}
              required
            />
          </Form.Group>

         
          <Form.Group>
            <Form.Label>Cadencia de cobro</Form.Label>
            <Form.Control
              as="select"
              value={cadence}
              onChange={e => setCadence(e.target.value)}
              required>
              <option value="">Selecciona una opción...</option>
              <option value="Mensual">Mensual</option>
              <option value="Evento">Por Evento</option>
            </Form.Control>
          </Form.Group>


          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>


          <Button className="modal-button btn btn-primary" type="submit">
            {initialData && initialData.id ? 'Actualizar' : 'Agregar'}         
          </Button>

          {initialData && initialData.id && (
            <Button className="modal-button-secondary btn btn-secondary" variant="secondary" onClick={handleDelete}>
              Eliminar
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClassModal; 
