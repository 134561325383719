import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ClassSelect from './ClassSelect';
import './styles/AddMemberModal.css'; // Import the new CSS file

const AddContactModal = ({ show, handleClose, saveMember, deleteMember, classes, member }) => {
    const [contactName, setContactName] = useState('');
    const [number, setNumber] = useState('');
    const [status, setStatus] = useState('');
    const [activityStatus, setActivitiyStatus] = useState('');
    const [selectedClassIds, setSelectedClassIds] = useState([]);
    const [showClassSelect, setShowClassSelect] = useState(false);

    useEffect(() => {
        if (member) {
            setContactName(member.contact_name);
            setNumber(member.number);
            setStatus(member.status);
            setSelectedClassIds(member.classIds || []);
        } else {
            setContactName('');
            setNumber('');
            setStatus('');
            setSelectedClassIds([]);
        }

    }, [member]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveMember({
            contact_name: contactName,
            number: number,
            status: status,
            classIds: selectedClassIds,


        });
        handleClose();
    };

    const handleClassChange = (e) => {
        const { value, checked } = e.target;
        setSelectedClassIds(prev =>
            checked ? [...prev, value] : prev.filter(id => id !== value)
        );
    };



    //FETCH AND EDIT RELATED CLASSES
    const toggleClassSelect = () => {
        setShowClassSelect(prevState => !prevState);
    };

  
    const handleRemoveClass = (classId) => {
        setSelectedClassIds(selectedClassIds.filter(id => id !== classId));
    };


    // DELETE MEMNBER
    const handleDelete = () => {
        if (member && member.id) {
            deleteMember(member.id);
            handleClose();
        }
    };


    const handleNumberChange = (e) => {
        const value = e.target.value;
        // Use a regular expression to allow only numeric input
        const cleaned = value.replace(/\D/g, ''); // Remove non-digit characters
    
        // Set the number only if it's 10 digits or fewer
        if (cleaned.length <= 10) {
            setNumber(cleaned);
        }
    };

  
    return (
        <Modal show={show} onHide={handleClose} className="fade" dialogClassName="modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title>{member ? 'Editar cliente' : 'Agregar cliente'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Nombre completo</Form.Label>
                        <Form.Control
                            type="text"
                            value={contactName}
                            onChange={e => setContactName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Celular (WhatsApp)</Form.Label>
                        <Form.Control
                            type="tel"  // Recommended to use 'tel' for telephone numbers
                            value={number}
                            onChange={handleNumberChange}
                            maxLength="10"  // Ensures the input does not exceed 10 digits
                            pattern="\d{10}"  // This pattern attribute enforces exactly 10 digits
                            required
                            placeholder="Enter 10-digit number"
                        />
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Estatus de pago</Form.Label>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            required>
                            <option value="">Selecciona una opción...</option>
                            <option value="Corriente">Corriente</option>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Atrasado">Atrasado</option>
                            <option value="Nuevo">Nuevo</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                    <Form.Label>Agregar servicios</Form.Label>
                    <Form.Control
                        type="text"
                        value="Selecciona uno o más servicios..."
                        onClick={toggleClassSelect}
                        readOnly
                        placeholder="Selecciona una opción..."
                    />
                    {showClassSelect && (
                        <ClassSelect 
                            classes={classes}
                            selectedClassIds={selectedClassIds}
                            handleClassChange={handleClassChange}
                        />
                    )}
                </Form.Group>

                {/* Conditionally render the pills container */}
                {!showClassSelect && (
                    <div className="tag-container">
                        {selectedClassIds.map(classId => {
                            const className = classes.find(cls => cls.id === classId)?.class_name;
                            return (
                                <div key={classId} className="tag-class-item">
                                    {className}
                                    <span onClick={() => handleRemoveClass(classId)}>×</span>
                                </div>
                            );
                        })}
                    </div>
                )}



                    <Button className="modal-button btn btn-primary" variant="primary" type="submit">
                        {member ? 'Guardar cambios' : 'Agregar'}
                    </Button>
                    {member && (
                        <Button className="modal-button-secondary btn btn-secondary" variant="secondary" onClick={handleDelete}>
                            Eliminar
                        </Button>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddContactModal;
