import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './styles/NavBar.css';

// Assuming you have icons saved like src/assets/icons/members-icon.svg
import { ReactComponent as MembersIcon } from './icons/members.svg';
import { ReactComponent as CatalogIcon } from './icons/classes.svg';
import { ReactComponent as AccountIcon } from './icons/user.svg';


const NavBar = () => {

    const location = useLocation();

    // Don't show NavBar on login or signup pages
    if (location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/' || location.pathname === '/payment-success' || location.pathname === '/reset-password') {
        return null;
    }

    return (
      <nav className="navbar-custom">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/members" className="nav-link" activeClassName="active">
              <MembersIcon className="navbar-icon"/>
              <span className='navbar-text'>Clientes</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/classes" className="nav-link" activeClassName="active">
              <CatalogIcon className="navbar-icon"/>
              <span className='navbar-text'>Servicios</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/profile" className="nav-link" activeClassName="active">
              <AccountIcon className="navbar-icon"/>
              <span className='navbar-text'>Cuenta</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  };
  
  export default NavBar;
