import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import AddClassModal from './AddClassModal';
import { getAuth } from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './styles/CatalogCard.css'; // Import the new CSS file
import './styles/SectionHeader.css'; // Import the new CSS module


const CatalogList = () => {
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    fetchClasses();
  }, []);

  const handleShowModal = (classData = null) => {
    setSelectedClass(classData);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const saveClass = async (classData) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    // Ensure 'cost' is converted to an integer
    const numericCost = parseInt(classData.cost, 10);
    if (isNaN(numericCost)) {
      console.error('Invalid cost:', classData.cost);
      return; // Prevent saving if cost is not a valid number
    }
  
    const classToSave = {
      ...classData,
      cost: numericCost, // Store the cost as an integer
      createdBy: user.uid
    };
  
    try {
      if (classData.id) {
        const classRef = doc(db, 'classes', classData.id);
        await updateDoc(classRef, classToSave);
      } else {
        await addDoc(collection(db, 'classes'), classToSave);
      }
      fetchClasses();
    } catch (error) {
      console.error('Error saving class:', error);
    }
    handleCloseModal();
  };
  


  const deleteClasses = async (classId) => {
    try {
        const classRef = doc(db, 'classes', classId);
        await deleteDoc(classRef);
        fetchClasses(); // Refresh the members list after deletion
    } catch (error) {
        console.error('Error deleting class:', error);
    }
};

  const fetchClasses = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    let baseQuery = collection(db, 'classes');
    let conditions = [where("createdBy", "==", user.uid) ];

    const q = query(baseQuery, ...conditions); 

    try {
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.class_name.localeCompare(b.class_name));
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const getCadenceClass = (cadence) => {
    switch (cadence.toLowerCase()) {
      case 'mensual':
        return 'mensual';
      case 'semanal':
        return 'semanal';
      case 'diario':
        return 'diario';
      case 'evento':
        return 'evento';
      default:
        return '';
    }
  };

  return (
    <div>
        <div className="section-header">
            <h3 className='section-title'>{classes.length} servicios</h3>
            <Button className="button-add" onClick={() => handleShowModal(null)}>+ Agregar</Button>
        </div>

        <AddClassModal
             show={showModal}
             handleClose={handleCloseModal}
             saveClass={saveClass}
             deleteClass={deleteClasses} 
             initialData={selectedClass}
        />

        <div className="row">
            {classes.map((item, index) => (
                <div key={index} className="col-sm-4">
                    <div className="catalog-card">
                        <div className="catalog-card-edit" onClick={() => handleShowModal(item)}>Editar</div>
                        <h5 className="catalog-card-title">{item.class_name}</h5>
                        <p className="catalog-card-price">${item.cost}</p>
                        <div className={`catalog-card-status ${getCadenceClass(item.cadence)}`}>{item.cadence}</div>
                        <p className="catalog-card-description">{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
);
};

export default CatalogList;
