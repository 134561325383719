import React, { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import AddContactModal from './AddContactModal';
import AddPaymentModal from './AddPaymentModal';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDoc, getFirestore, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import axios from 'axios';
import './styles/MemberCard.css'; // Import the new CSS file
import './styles/SectionHeader.css';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { useAuth } from './contexts/AuthContexts.js'; 



const ContactList = () => {
    const [members, setMembers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [classes, setClasses] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false); 
    const [filterStatus, setFilterStatus] = useState('');
    const { currentUser, additionalUserInfo } = useAuth();  // This is the correct place to call useAuth
    const [userData, setUserData] = useState(null);



    useEffect(() => {
        const fetchUserData = async () => {
            if (!currentUser) {
                console.error('No current user found');
                return;
            }

            const db = getFirestore();
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error('User data not found');
                return;
            }

            const userData = userSnap.data();
            setUserData(userData);
            console.log('User data fetched:', userData);

            fetchMembers(); // Now fetching members might depend on user data
            fetchClasses(); // Same as above
        };

        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser, filterStatus]);


    const handleShowModal = (member = null) => {
        setSelectedMember(member);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const applyFilter = (status) => {
        setFilterStatus(status);
    };

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
      };


      const handleFilterClick = () => {
        document.getElementById('hidden-select').click(); // Trigger the hidden select click
      };
      

      const saveMember = async (memberData) => {
        const auth = getAuth();
        const user = auth.currentUser;
        console.log('Stripe Account ID:', currentUser.stripeAccountId);
    
        if (!user) {
            console.error('No user logged in!');
            return;
        }

        const stripeAccountId = userData.stripeAccountId;

    
        try {
            if (selectedMember) {
                const docRef = doc(db, 'members', selectedMember.id);
                await updateDoc(docRef, {
                    ...memberData,
                    active_status: "active",
                    updatedBy: user.uid
                });
            } else {
                const docRef = await addDoc(collection(db, 'members'), {
                    ...memberData,
                    createdBy: user.uid,
                    active_status: "active",
                });
                // Ensure memberData has the newly created ID if necessary
                memberData.id = docRef.id;
    
                // Create Stripe customer
                try {
                    console.log('Stripe Account ID:', stripeAccountId);

                if (!stripeAccountId) {
                    console.error('Stripe account ID is missing, cannot create Stripe customer');
                    return; // Exit the function or handle this case appropriately
                }
                    const response = await axios.post('/api/members/create-stripe-customer', {
                        id: memberData.id,
                        contact_name: memberData.contact_name,
                        number: memberData.number,
                        stripeAccountId: stripeAccountId
                    });
                    console.log('Stripe Customer Created:', response.data);
                } catch (error) {
                    console.error('Failed to create Stripe customer:', error);
                }
            }
    
            // Refresh member list after all operations
            fetchMembers();
        } catch (error) {
            console.error('Error saving member:', error);
        }
    };

    const deleteMember = async (memberId) => {
        try {
            const memberRef = doc(db, 'members', memberId);
            await deleteDoc(memberRef);
            fetchMembers(); // Refresh the members list after deletion
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    const updateMemberActiveStatus = async (memberId) => {
        const auth = getAuth();
        const user = auth.currentUser;
    
        if (!user) {
            console.error('No user logged in!');
            return;
        }
    
        try {
            const docRef = doc(db, 'members', memberId);
            await updateDoc(docRef, {
                active_status: "inactive"  // Set status to inactive instead of deleting
            });
            fetchMembers();  // Refresh the list of members after updating
        } catch (error) {
            console.error('Error updating member status:', error);
        }
    };

    const fetchMembers = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('No user logged in!');
          return;
        }
      
        let baseQuery = collection(db, 'members');
        let conditions = [where("createdBy", "==", user.uid)];
      
        if (filterStatus && filterStatus !== 'all') { // Apply filter only if not 'all'
          conditions.push(where("status", "==", filterStatus));
        }
      
        const q = query(baseQuery, ...conditions);
        try {
          const querySnapshot = await getDocs(q);
          const membersData = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => a.contact_name.localeCompare(b.contact_name)); // Sorting alphabetically by contact_name

          setMembers(membersData);
        } catch ( error ) {
          console.error('Error fetching members:', error);
        }
      };
      

    const fetchClasses = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) return;

        const q = query(collection(db, 'classes'), where("createdBy", "==", user.uid));
        try {
            const querySnapshot = await getDocs(q);
            const fetchedClasses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setClasses(fetchedClasses);
        } catch (error) {
            console.error('Error fetching classes:', error);
        }
    };

    const getClassNames = (classIds) => {
        if (!classIds) return '';
        return classIds.map(id => {
            const cls = classes.find(c => c.id === id);
            return cls ? cls.class_name : '';
        }).join(', ');
    };


    const sendSinglePayment = async (memberId, method) => {
        
        if (!currentUser || !additionalUserInfo.stripeAccountId) {
            alert("Stripe account ID is not available. Please log in again.");
            return;
        }

        const member = members.find(m => m.id === memberId); // Get the member data
        if (!member) {
            alert("Member data not found.");
            return;
        }

        const dataToSend = {
            memberId,
            stripeAccountId: additionalUserInfo.stripeAccountId,
            classIds: member.classIds,
            phoneNumber: member.number,
            memberName : member.contact_name,
            userId: member.createdBy,
            customerId: member.stripeCustomerId,
            method
        };

        console.log("Sending payment link with the following data:", dataToSend);

        try {
            const response = await axios.post('/api/payments/send-payment-link', dataToSend);
            alert('Payment link sent successfully!');
        } catch (error) {
            console.error('Error sending payment link:', error);
            alert(`Failed to send payment link: ${error.message}`);
        }
    };


    const handleShowSinglePaymentModal = (member, userData) => {
        setSelectedMember(member);
        setUserData(userData);
        setShowSinglePaymentModal(true);
    };

    const handleCloseSinglePaymentModal = () => setShowSinglePaymentModal(false);


    const sendSPEIPaymentLink = async (memberId) => {
        const member = members.find(m => m.id === memberId);
        if (!member) {
            alert("Member data not found.");
            return;
        }
    
        const dataToSend = {
            memberId,
            stripeAccountId: userData.stripeAccountId,
            totalAmount: 5000,  // Should be dynamically calculated
        };
    
        console.log("Creating SPEI payment intent with the following data:", dataToSend);
    
        try {
            const speiResponse = await axios.post('/api/payments/create-spei-payment-intent', dataToSend);
            if (speiResponse.data && speiResponse.data.paymentIntentId) {
                const sessionData = {
                    totalAmount: 5000,  // Reuse or recalculate as necessary
                    memberId,
                    stripeAccountId: userData.stripeAccountId,
                    paymentIntentId: speiResponse.data.paymentIntentId, 
                    memberId: member.stripeCustomerId
                };
                
                const sessionResponse = await axios.post('/api/payments/create-bank-transfer-session', sessionData);
                if (sessionResponse.data.url) {
                    console.log('Redirecting to payment page:', sessionResponse.data.url);
                    //window.location.href = response.data.url; // Redirect the user to the payment URL
                }
            }
        } catch (error) {
            console.error('Error during payment process:', error);
            alert(`Failed to process payment: ${error.message}`);
        }
    };


    const getStatusClass = (status) => {
        switch (status) {
            case 'Corriente':
                return 'corriente';
            case 'Pendiente':
                return 'pendiente';
            case 'Atrasado':
                return 'atrasado';
            case 'Nuevo':
                return 'nuevo';
            default:
                return '';
        }
    };

    // Update Dropdown.Item onClick to call applyFilter
<Dropdown.Item onClick={() => applyFilter('Corriente')}>Corriente</Dropdown.Item>

    return (
        <div>
            <div className="section-header">
                <h3 className='section-title'>{members.length} clientes</h3>
                <div className="button-group">
                <div className="filter-container">

                    <select
                    
                        id="filter-select"
                        value={filterStatus}
                        onChange={handleFilterChange}
                        className={`button-filter ${filterStatus && filterStatus !== 'all' ? 'active-filter' : ''}`}
                    >
                        <option value="all">Filtrar</option>
                        <option value="Corriente">Corriente</option>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Atrasado">Atrasado</option>
                        <option value="Nuevo">Nuevo</option>
                    </select>
                </div>
                    <Button className="button-add" onClick={() => handleShowModal(null)}>+ Agregar</Button>
                    
                </div>
                </div>

            <AddContactModal
                show={showModal}
                handleClose={handleCloseModal}
                saveMember={saveMember}
                deleteMember={deleteMember} // Pass the deleteMember function
                classes={classes}
                member={selectedMember}
            />

            <AddPaymentModal
                show={showSinglePaymentModal}
                handleClose={handleCloseSinglePaymentModal}
                classes={classes}
                member={selectedMember}
                sendSinglePayment = {sendSinglePayment}
                userData={userData}
            />

            <div className="row">
                {members.map((member, index) => (
                    <div key={index} className="col-sm-4">
                        <div className="card">
                            <div className={`card-status ${getStatusClass(member.status)}`}>{member.status}</div>
                            <div className="card-body">
                                <h5 className="card-title">{member.contact_name}</h5>
                                <p className="card-contact-info">
                                    <a href={`https://wa.me/${member.number}`}>{member.number}</a>
                                </p>
                                <div className="card-classes">
                                    {member.classIds && member.classIds.map((classId) => (
                                        <span key={classId}>{getClassNames([classId])}</span>
                                    ))}
                                </div>
                                
                                <div className="card-edit" onClick={() => handleShowModal(member)}>Editar</div>
                              {/* <div className="card-edit" onClick={() => handleShowSinglePaymentModal(member, userData)}>Agregar Pago</div> */}
                                {/*<Button variant="secondary" onClick={() => sendSPEIPaymentLink(member.id)}>Enviar SPEI</Button>*/}


                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContactList;