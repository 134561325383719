import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBHvTGCu7dkNj41TuwU0N50pXBCnPIFFWY",
    authDomain: "subzy-beta.firebaseapp.com",
    projectId: "subzy-beta",
    storageBucket: "subzy-beta.appspot.com",
    messagingSenderId: "382108984485",
    appId: "1:382108984485:web:7be8bbd082968fe149994c",
    measurementId: "G-6YEWZ06LH9"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);


export { auth, db };