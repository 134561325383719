import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './contexts/AuthContexts.js';


function Register() {
    const [formData, setFormData] = useState({
        businessName: '',
        ownerName: '',
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const { login } = useAuth();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
          const response = await axios.post('/api/users/signup', formData);
          if (response.data.token) {
              await login(response.data.token);
            // Login with the received token


              if (response.data.stripeAccountId) {
                  
                // If Stripe account creation was successful, redirect to the Stripe Connect flow
                  const stripeResponse = await axios.post('/api/stripe/create-connect-account', {accountId: response.data.stripeAccountId} );
                 /* if (stripeResponse.data.url) {
                      window.location.href = stripeResponse.data.url; // Redirects the user to Stripe
                  } else {
                      console.error('Failed to retrieve Stripe Connect URL');
                      navigate('/onboarding'); // Navigate to the profile page as a fallback
                  }*/
              } else {
                  navigate('/onboarding'); // Navigate to the profile page as a fallback if no Stripe account
              }
          } else {
              console.error('Signup failed: No token received');
          }
      } catch (error) {
          console.error('Signup failed:', error.response?.data?.error || 'Unknown error');
          setError(error.response?.data?.error || 'An unknown error occurred');
      } finally {
          setLoading(false);
      }
  };

  return (
    <div className="container mt-5">
      <h2 className="title-login-register">Crea tu cuenta</h2>

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="businessName" className="label-login-register-form form-label">Nombre de tu comunidad</label>
          <input
            type="text"
            className="login-form-input form-control"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="ownerName" className="label-login-register-form form-label">Tu nombre completo</label>
          <input
            type="text"
            className="login-form-input form-control"
            id="ownerName"
            name="ownerName"
            value={formData.ownerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="email" className="label-login-register-form form-label">Correo electrónico</label>
          <input
            type="email"
            className="login-form-input form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="password" className="label-login-register-form form-label">Contraseña</label>
          <input
            type="password"
            className="login-form-input form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className='privacy-tos'>
          <p>Al crear tu cuenta, aceptas nuestro Aviso de Privacidad y los Términos y Condiciones.</p>
        </div>
        <button type="submit" className={`loginbtn btn btn-primary ${loading ? 'loading' : ''}`}  disabled={loading}>
          {loading ? <span className="spinner"></span> : 'Crear cuenta'}
        </button>
      </form>
      <div>
        <p className='alt-register-login'>¿Ya tienes cuenta? <a href="/login">Inicia sesión</a> </p>
      </div>
    </div>
  );
}

export default Register;