import React, { useState } from 'react';
import { auth } from './config/firebase-config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import './styles/OnboardingFlow.css';


function PasswordReset() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Se ha enviado un correo electrónico para restablecer la contraseña. Por favor, revise su bandeja de entrada.');
    } catch (error) {
      console.error('Password reset failed', error.message);
      setError('No se pudo enviar el correo electrónico para restablecer la contraseña. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className='title-login-register'>Restablecer contraseña</h2>
      <p>Ingresa tu correo electrónico y te enviaremos un enlace para que puedas restablecer tu contraseña.</p>
      {message && (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="email" className="label-login-register-form form-label">Correo electrónico</label>
          <input
            type="email"
            className="login-form-input form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="loginbtn btn btn-primary">Reestablecer Contraseña</button>
      </form>
      <div className="mt-3">
        <p className='alt-register-login'>¿Recordaste tu contraseña? <Link to="/login">Volver al inicio de sesión</Link></p>
      </div>
    </div>
  );
}

export default PasswordReset;