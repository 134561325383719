import React from 'react';
import './styles/AddMemberModal.css';

const ClassSelect = ({ classes, selectedClassIds, handleClassChange }) => {
  return (
    <div className="class-select">
      {classes.map(cls => (
        <label key={cls.id} className={`class-select-option ${selectedClassIds.includes(cls.id) ? 'selected' : ''}`}>
          <input
            type="checkbox"
            value={cls.id}
            checked={selectedClassIds.includes(cls.id)}
            onChange={handleClassChange}
          />
          <span className="class-info">
            <span className="class-name">{cls.class_name}</span>
            <span className="class-cost">${cls.cost}</span>
          </span>
        </label>
      ))}
    </div>
  );
};

export default ClassSelect;