import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ClassSelect from './ClassSelect';
import axios from 'axios';
import './styles/AddMemberModal.css'; // Import the new CSS file


const AddContactModal = ({ show, handleClose, sendSinglePayment, deleteMember, classes, member, userData}) => {
    const [contactName, setContactName] = useState('');
    const [number, setNumber] = useState('');
    const [status, setStatus] = useState('');
    const [activityStatus, setActivitiyStatus] = useState('');
    const [selectedClassIds, setSelectedClassIds] = useState([]);
    const [showClassSelect, setShowClassSelect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stripeAccountId, setStripeAccountId] = useState('');


    useEffect(() => {
        if (member) {
            setContactName(member.contact_name);
            setNumber(member.number);
            setStatus(member.status);
            setSelectedClassIds(member.classIds || []);
        } else {
            setContactName('');
            setNumber('');
            setStatus('');
            setSelectedClassIds([]);
        }

        if (userData){
            setStripeAccountId(userData.stripeAccountId);
            console.log('Stripe Account Id is', stripeAccountId);
        } else {
            setStripeAccountId('');
        }

    }, [member, userData]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('/api/payments/send-payment-link', {
                memberId: member.id,
                stripeAccountId: stripeAccountId, // Ensure this is available in your member object
                classIds: selectedClassIds,
                phoneNumber: member.number,
                //memberName: member.contact_name,
                userId: member.createdBy,
                customerId: member.stripeCustomerId
            });

            alert('Payment link sent successfully!');
            handleClose();
        } catch (error) {
            console.error('Error sending payment link:', error);
            alert('Failed to send payment link. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleClassChange = (e) => {
        const { value, checked } = e.target;
        setSelectedClassIds(prev =>
            checked ? [...prev, value] : prev.filter(id => id !== value)
        );
    };



    //FETCH AND EDIT RELATED CLASSES
    const toggleClassSelect = () => {
        setShowClassSelect(prevState => !prevState);
    };

  
    const handleRemoveClass = (classId) => {
        setSelectedClassIds(selectedClassIds.filter(id => id !== classId));
    };



    const handleNumberChange = (e) => {
        const value = e.target.value;
        // Use a regular expression to allow only numeric input
        const cleaned = value.replace(/\D/g, ''); // Remove non-digit characters
    
        // Set the number only if it's 10 digits or fewer
        if (cleaned.length <= 10) {
            setNumber(cleaned);
        }
    };

  
    return (
        <Modal show={show} onHide={handleClose} className="fade" dialogClassName="modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Crear pago único</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Nombre del cliente</Form.Label>
                        <Form.Control
                            type="text"
                            value={contactName}
                            onChange={e => setContactName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Celular (WhatsApp)</Form.Label>
                        <Form.Control
                            type="tel"  // Recommended to use 'tel' for telephone numbers
                            value={number}
                            onChange={handleNumberChange}
                            maxLength="10"  // Ensures the input does not exceed 10 digits
                            pattern="\d{10}"  // This pattern attribute enforces exactly 10 digits
                            required
                            placeholder="Enter 10-digit number"
                        />
                    </Form.Group>

                    <Form.Group>
                    <Form.Label>Agregar servicios</Form.Label>
                    <Form.Control
                        type="text"
                        value="Selecciona uno o más servicios..."
                        onClick={toggleClassSelect}
                        readOnly
                        placeholder="Selecciona una o más opciones..."
                    />
                    {showClassSelect && (
                        <ClassSelect 
                            classes={classes}
                            selectedClassIds={selectedClassIds}
                            handleClassChange={handleClassChange}
                        />
                    )}
                </Form.Group>

                {/* Conditionally render the pills container */}
                {!showClassSelect && (
                    <div className="tag-container">
                        {selectedClassIds.map(classId => {
                            const className = classes.find(cls => cls.id === classId)?.class_name;
                            return (
                                <div key={classId} className="tag-class-item">
                                    {className}
                                    <span onClick={() => handleRemoveClass(classId)}>×</span>
                                </div>
                            );
                        })}
                    </div>
                )}

           


                    <Button className="modal-button btn btn-primary" variant="primary" type="submit">
                        Enviar link de pago
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddContactModal;
