import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/OnboardingFlow.css';  // This line imports the CSS styles

const OnboardingFlow = () => {
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');
  const [penaltyRate, setPenaltyRate] = useState('');
  const [communityName, setCommunityName] = useState('');
    const [communityType, setCommunityType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommunityData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in!');
        return;
      }

      const idToken = await user.getIdToken();
      const communityId = user.uid; // Assuming the user's UID is the community ID

      try {
        const response = await axios.get(`/api/community/details/${communityId}`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        if (response.data) {
          const { name, type, startDay, endDay, penaltyRate } = response.data;
          setCommunityName(name);
          setCommunityType(type);
          setStartDay(startDay);
          setEndDay(endDay);
          setPenaltyRate(penaltyRate.toString()); // Ensuring penaltyRate is set as a string for the input field
        }
      } catch (error) {
        console.error('Error fetching community data:', error);
      }
    };

    fetchCommunityData();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    // Convert startDay and endDay to integers
    const startDayInt = parseInt(startDay, 10);  // Ensure the day values are integers
    const endDayInt = parseInt(endDay, 10);
    const penaltyRateInt = parseInt(penaltyRate, 10);
  
    // Get the Firebase ID token
    user.getIdToken().then(async (idToken) => {
      const communityId = user.uid; // Assuming the user's UID is used as the community ID; adjust as necessary
  
      try {
        const response = await axios.post('/api/community/update', {
          communityId,
          startDay: startDayInt,
          endDay: endDayInt,
          communityName, // Added community name
            communityType, // Added community type
          penaltyRate: penaltyRateInt
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`  // Sending the Firebase ID token in the Authorization header
          }
        });
  
        if (response.status === 200) {
          console.log('Community updated successfully');
          navigate('/profile'); // Navigate to the profile page on successful update
        } else {
          console.error('Failed to update community data');
        }
      } catch (error) {
        console.error('Error submitting community updates:', error);
      }
    }).catch(error => {
      console.error('Failed to get Firebase ID token', error);
    });
  };


  return (
    <div className="onboarding-container">
      <h1 className="onboarding-title">Completa la información de tu negocio</h1>
      <form onSubmit={handleSubmit} className="onboarding-form">
  <div className="input-group">
    <label htmlFor="communityName">Nombre de tu negocio</label>
    <input
      type="text"
      id="communityName"
      value={communityName}
      onChange={e => setCommunityName(e.target.value)}
      required
    />
  </div>
  
  {/* Existing inputs remain unchanged */}
  <div className="input-group">
    <label>¿Qué día empieza el ciclo de pago?</label>
    <input
      type="number"
      min="1"
      max="31"
      value={startDay}
      onChange={e => setStartDay(e.target.value)}
      required
    />
  </div>
  <div className="input-group">
    <label>¿Qué día termina el ciclo de pago?</label>
    <input
      type="number"
      min="1"
      max="31"
      value={endDay}
      onChange={e => setEndDay(e.target.value)}
      required
    />
  </div>
  <div className="input-group">
    <label>¿Qué porcentaje cobras por pago atrasado? (%)</label>
    <input
      type="number"
      min="0"
      max="100"
      value={penaltyRate}
      onChange={e => setPenaltyRate(e.target.value)}
      required
    />
  </div>

  <div className="input-group">
    <label htmlFor="communityType">Tipo de negocio</label>
    <select
      id="communityType"
      value={communityType}
      onChange={e => setCommunityType(e.target.value)}
      className="type-select"
      required
    >
      <option value="">Selecciona una opción...</option>
      <option value="Consultorio">Consultorio</option>
      <option value="Academia de artes">Academia de artes</option>
      <option value="Escuela de idiomas">Escuela de idiomas</option>
      <option value="Estudio de fitness">Estudio de fitness</option>
      <option value="Taller">Taller</option>
      <option value="Tutor(a)">Tutor(a)</option>
      <option value="Otros">Otros</option>
    </select>
  </div>


  <button type="submit" className="save-button btn btn-primary">Guardar información</button>
</form>
    </div>
  );
};

export default OnboardingFlow;