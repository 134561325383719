import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './config/firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful', userCredential.user);
      navigate('/members');
    } catch (error) {
      console.error('Login failed', error.message);
      
      // Handle specific error cases
      let errorMessage = '';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'No existe una cuenta con este correo electrónico. Por favor, regístrese primero.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Contraseña incorrecta. Por favor, inténtelo de nuevo.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'El correo electrónico no es válido. Por favor, verifique e inténtelo de nuevo.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'Esta cuenta ha sido deshabilitada. Por favor, contacte al soporte.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Demasiados intentos fallidos. Por favor, inténtelo más tarde.';
          break;
        default:
          errorMessage = 'Ocurrió un error al iniciar sesión. Por favor, inténtelo de nuevo.';
      }
      setError(`${errorMessage} (Código de error: ${error.code})`);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className='title-login-register'>Ingresa a tu cuenta</h2>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="email" className="label-login-register-form form-label">Correo electrónico</label>
          <input
            type="email"
            className="login-form-input form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="password" className="label-login-register-form form-label">Contraseña</label>
          <input
            type="password"
            className="login-form-input form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="loginbtn btn btn-primary">Iniciar sesión</button>
      </form>
      <div className="mt-3">
        <p className='alt-register-login'>¿Aún no tienes cuenta? <Link to="/signup">Regístrate</Link></p>
        <p className='alt-register-login'>¿Olvidaste tu contraseña? <Link to="/reset-password">Restablece tu contraseña</Link></p>
      </div>
    </div>
  );
}

export default Login;