import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure this comes before any custom CSS imports

// Custom Styles
import './App.css';
import './styles/NavBar.css';

import './styles/MainBar.css';
import './styles/SectionHeader.css';




import ContactList from './ContactList';
import CatalogList from './CatalogList';
import LogIn from './LogIn';
import Register from './Register';
import Profile from './Profile';
import PrivateRoute from './PrivateRoute';
import logo from './subzy-logo.png';
import Success from './Success';
import Reauth from './Reauth';
import NavBar from './NavBar'; // Import the NavBar component
import PaymentSuccess from './PaymentSuccess';
import OnboardingFlow from './OnboardingFlow';
import ResetPassword from './ResetPassword.js';
import { AuthProvider, useAuth } from './contexts/AuthContexts';



const App = () => {

  
    return (
    <div className="container">
      <div className="main-bar">
        <a href="/">
          <img className="logo" src={logo} alt="subzy" />
        </a>
        <p className="beta-text">BETA</p>
      </div>
     
      <NavBar /> 
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/success" element={<Success />} />
        <Route path="/reauth" element={<Reauth />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/spei-pay" element = {<speiData />}> </Route>
        <Route path="/reset-password" element = {<ResetPassword />} />


        <Route element={<PrivateRoute />}>
          <Route path="/members" element={<ContactList />} />
          <Route path="/classes" element={<CatalogList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/onboarding" element={<OnboardingFlow />} />

        </Route>
      </Routes>
    
    </div>
  );
};

export default App;
