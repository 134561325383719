import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './contexts/AuthContexts.js';
import { auth } from './config/firebase-config';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
  // Import your CSS file
import './styles/SectionHeader.css';
import './styles/Profile.css';
import logosmall from './subzy-logo-small-neutral.png';


function Profile() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [stripeAccountId, setStripeAccountId] = useState('');
    const [stripeLink, setStripeLink] = useState('');
    const [stripeData, setStripeData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [communityData, setCommunityData] = useState(null);
    const [balance, setBalance] = useState({ available: 0, pending: 0 });


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (!currentUser) {
                    console.error('No current user found');
                    return;
                }
                const db = getFirestore();
                const userRef = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error('User data not found');
                    return;
                }

                const userData = userSnap.data();
                setUserData(userData);

                if (userData.communityId) {
                    const communityRef = doc(db, 'communities', userData.communityId);
                    const communitySnap = await getDoc(communityRef);

                    if (communitySnap.exists()) {
                        setCommunityData(communitySnap.data());
                    }
                }

                console.log('Fetching Stripe account ID for user:', currentUser.uid);
                const response = await axios.post('/api/stripe/get-stripe-account-id', { uid: currentUser.uid });
                const { stripeAccountId } = response.data;
                if (!stripeAccountId) {
                    console.error('No Stripe account ID found');
                    return;
                }
                console.log('Fetched Stripe account ID:', stripeAccountId);
                setStripeAccountId(stripeAccountId);
             

                fetchBalance(currentUser.uid);
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser]);

    const fetchStripeData = async (accountId) => {
        try {
            if (!accountId) {
                throw new Error('No accountId provided');
            }
            console.log('Fetching Stripe data for account:', accountId);
            const response = await axios.post('/api/stripe/edit-connect-account', { accountId });
            const { url } = response.data;
            if (url) {
                window.location.href = url;  // Redirect immediately when URL is fetched
            }
        } catch (error) {
            console.error('Error fetching Stripe account data:', error.message);
        }
     };

    const fetchBalance = async (userId) => {
        if (!userId) return;  // Ensure there's a user ID before making the call
        try {
            const response = await axios.get(`/api/get-balance/${userId}`);
            const balanceData = response.data;
            let available = 0;
            let pending = 0;
            
            // Sum all available amounts
            balanceData.available.forEach(item => {
                available += item.amount;
            });
    
            // Sum all pending amounts
            balanceData.pending.forEach(item => {
                pending += item.amount;
            });
    
            // Convert from cents to dollars assuming currency is in cents
            setBalance({ 
                available: available / 100,
                pending: pending / 100
            });
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div>
            <div className="section-header">
                <h2 className='section-title'>Cuenta</h2>
            </div>


            <div className="current-plan-short">
                <p>Plan actual </p>
                <span></span>
                <p className='plan-tag'> Trial</p>
            </div>


            <div className="balance-cards">

            <div className="balance-card-pending">
                    <p className="balance-amount">${balance.pending.toFixed(2)} MXN</p>
                    <h3>En tránsito</h3>
                </div>

                <div className="balance-card">
                   <p className="balance-amount">${balance.available.toFixed(2)} MXN</p>
                    <div className='balance-card-title'><h3>Listo para retirar</h3></div>
                </div>

            </div>

        

            {/* {userData && (
                <div>

                    <div className="profile-data-group">


                 <div className='profile-group-header'>
                                <h3>INFORMACIÓN PERSONAL</h3>
                                 <button onClick={() => navigate('/onboarding')} className="edit-profile-button btn btn-primary">Editar</button>   

                            </div>
                            <p>{userData.ownerName}</p>
                            <p>{userData.email}</p>
                    </div>
                


                 {communityData && (
                        <div className="profile-data-group">
                            <div className='profile-group-header'>
                                <h3>INFORMACIÓN DEL NEGOCIO</h3>
                                <button onClick={() => navigate('/onboarding')} className="edit-profile-button btn btn-primary">Editar</button>

                            </div>
                           

                            <div className='profile-data-fields'>
                        
                                <p>{communityData.name}</p>
                            </div>
                            <div className='profile-data-fields'>

                                <p>Cobramos del {communityData.startDay} al {communityData.endDay} de cada mes</p>
                            </div>

                            <div className='profile-data-fields'>
                                <p> {communityData.penaltyRate} % extra por pago atrasado</p>
                            </div>
                          
                            <div className='profile-data-fields'>
                                <p> {communityData.communityType}</p>
                            </div>
                           


                       
           
                        </div> 
                    )} 
                </div>
            )}*/}


            <div className='menu-item'>
            <div className='menu-item-text'>
                <h3>Información personal</h3>
                <p>Datos básicos y de contacto</p>
            </div>    

                <button onClick={() => navigate('/onboarding')} className="edit-profile-button btn btn-primary">Editar</button>
            </div>
           
            <div className='menu-item'>
             <div className='menu-item-text'>
                <h3>Negocio</h3>
                <p>Información general, horarios</p>
                </div>
                <button onClick={() => navigate('/onboarding')} className="edit-profile-button btn btn-primary">Editar</button>
            </div>
           

            

      
            <div className='menu-item'>
            <div className='menu-item-text'>
                <h3>Pagos</h3>
                <p>Pagos y cuentas para recibir</p>
            </div>
                <button onClick={() => fetchStripeData(stripeAccountId)} className="edit-profile-button btn btn-primary">Editar</button>
            </div>
           
                







            <div className='logout-group'>
            {currentUser ? (
                    <button onClick={handleLogout} className="logoutbutton btn btn-link">Cerrar Sesión</button>

            ) : (
            <p className="nav-item">Not authenticated</p>
            )}
        </div>







        <div className='extra-info'>

        <img className="logo-small" src={logosmall} alt="subzy" />


            <p className='footnotes'>
                Beta v1.0
            </p>

            <p className='footnotes'>
                2024 Subzy
            </p>

            <p className='footnotes'>
                Todos los derechos reservados
            </p>
        </div>


        </div>
    );
}

export default Profile;
